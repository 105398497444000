import { Link } from "gatsby"
import React, { Component } from "react"
import Button from "../../components/base/Button"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import Hero from "../../components/marketing/Hero"
import LeftContentCard from "../../components/marketing/LeftContentCard"
import RightContentCard from "../../components/marketing/RightContentCard"
import SubscribeAction from "../../components/marketing/SubscribeAction"
import Whatsapp from "../../components/marketing/Whatsapp"
import AppointmentScheduling from "../../images/appointment_scheduling.webp"
import OilCollecting from "../../images/oil_collection.webp"
import OilCollectionDrum from "../../images/oil_collection_drum.webp"
import OilStoring from "../../images/oil_storing.webp"
import RegisterAccount from "../../images/register_account.webp"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

class HowToRecyclePage extends Component {
  state = {
    visible: false,
    loading: false,
  }

  render() {
    const language = Language.CH
    return (
      <div className="bg-white">
        <SEO
          title="Arus Oil - 再循环方法"
          description="通过这个循序渐进的教程回收用过的食用油。注册一个帐户并开始在 Arus Oil 回收用过的食用油。"
        />
        <Header page="食用油再循环" />
        <Hero
          title={localeContent(language).recycleUCO.heroTitle}
          content={localeContent(language).recycleUCO.heroDescription}
          image={OilCollectionDrum}
          size="small"
          language={language}
        />
        <LeftContentCard
          title={localeContent(language).recycleUCO.leftContentCard1.title}
          content={
            localeContent(language).recycleUCO.leftContentCard1.description
          }
          image={OilCollecting}
        />
        <RightContentCard
          title={localeContent(language).recycleUCO.rightContentCard1.title}
          content={
            localeContent(language).recycleUCO.rightContentCard1.description
          }
          image={OilStoring}
        />
        <LeftContentCard
          title={localeContent(language).recycleUCO.leftContentCard2.title}
          content={
            localeContent(language).recycleUCO.leftContentCard2.description
          }
          image={RegisterAccount}
          callToAction={
            <Link to="/auth/signUp">
              <div className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                <div className="mt-2 sm:mt-0">
                  <Button className="w-full" type="normal" text="立即注册" />
                </div>
              </div>
            </Link>
          }
        />
        <RightContentCard
          title={localeContent(language).recycleUCO.rightContentCard2.title}
          content={
            localeContent(language).recycleUCO.rightContentCard2.description
          }
          image={AppointmentScheduling}
        />
        <Whatsapp />
        <SubscribeAction language={language} />
        <Footer />
      </div>
    )
  }
}

export default HowToRecyclePage
